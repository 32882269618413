import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'w2w-feeds',
  templateUrl: './wings2win-feeds.component.html',
  styleUrls: ['./wings2win-feeds.component.scss']
})
export class Wings2winFeedsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
