import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'w2w-studentcare',
  templateUrl: './studentcare.component.html',
  styleUrls: ['./studentcare.component.scss']
})
export class StudentcareComponent implements OnInit {
  title = 'STUDENT CARE - Wings2Win is strictly devoted for the students';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'First time we offer the unique way of instant education system to teach needy students at all situations and scopes in life.' }
    );
  }

}
