import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'w2w-allcourses',
  templateUrl: './allcourses.component.html',
  styleUrls: ['./allcourses.component.scss']
})
export class AllcoursesComponent implements OnInit {

  title = 'Wings2Win Courses - Offering Spoken English, Magnetic Personality Traits, Interview Management, Soft Skills Enhancement';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Offering total interpersonal and dynamic skills for all students, candidates, professionals and every individual to win every battle of life.' }
    );
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
