import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'w2w-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  title = 'About Wings2Win - A one-stop solution for all students, candidates, professionals and every individual';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Wings2Win is established in the year 2019 – August 12th. It took (SIX) years of research and vision to come true as Wings2Win concept took birth actually in the year 2013 – December for bringing a revolution among youth.' }
    );
  }

}
