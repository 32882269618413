import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'w2w-director',
  templateUrl: './director.component.html',
  styleUrls: ['./director.component.scss']
})
export class DirectorComponent implements OnInit {
  title = 'Director Wings2Win - Face this competitive world in the most effective rational & confident way';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'The director of Wings2Win cordially wish all the very best to each individual may be a student, candidate, professional or a fresher for your all test, exams, interviews, dreams, goals and finally for all your commitments with your life.' }
    );
  }

}
