import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatTabsModule, MatDialogModule, MatButtonModule, MatBadgeModule } from '@angular/material';

import { AppRoutingModule, routeComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConstructionComponent } from './construction/construction.component';
import { ComponentsComponent } from './components/components.component';
import { MenuComponent } from './components/menu/menu.component';
import { SliderComponent } from './components/slider/slider.component';
import { FooterComponent } from './components/footer/footer.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { DirectorComponent } from './pages/director/director.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AllcoursesComponent } from './pages/allcourses/allcourses.component';
import { OurservicesComponent } from './pages/ourservices/ourservices.component';
import { CorporatesolutionComponent } from './pages/corporatesolution/corporatesolution.component';
import { WethechangeComponent } from './pages/wethechange/wethechange.component';
import { StudentcareComponent } from './pages/studentcare/studentcare.component';
import { SundayScholarComponent } from './pages/sunday-scholar/sunday-scholar.component';
import { InstantEducationSystemComponent } from './pages/instant-education-system/instant-education-system.component';
import { BecomeourworldComponent } from './pages/becomeourworld/becomeourworld.component';
import { ClassroomComponent } from './pages/classroom/classroom.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { Wings2winFeedsComponent } from './components/wings2win-feeds/wings2win-feeds.component';


@NgModule({
  declarations: [
    AppComponent,
    routeComponents,
    ConstructionComponent,
    ComponentsComponent,
    MenuComponent,
    SliderComponent,
    FooterComponent,
    AboutusComponent,
    ContactusComponent,
    DirectorComponent,
    AllcoursesComponent,
    OurservicesComponent,
    CorporatesolutionComponent,
    WethechangeComponent,
    StudentcareComponent,
    SundayScholarComponent,
    InstantEducationSystemComponent,
    BecomeourworldComponent,
    ClassroomComponent,
    NotificationsComponent,
    Wings2winFeedsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatDialogModule,
    MatButtonModule,
    MatBadgeModule
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
