import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'w2w-ourservices',
  templateUrl: './ourservices.component.html',
  styleUrls: ['./ourservices.component.scss']
})
export class OurservicesComponent implements OnInit {
  title = 'Wings2Win Services - The world class academy of impact learning & life skills management training center in Bhubaneswar, Odisha';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'Wings2Win is Impact learning & lifeskills management a one-stop solution for all students, candidates, professionals and every individual' }
    );
  }
}
