import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
// import { ConstructionComponent } from './construction/construction.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { DirectorComponent } from './pages/director/director.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AllcoursesComponent } from './pages/allcourses/allcourses.component';
import { OurservicesComponent } from './pages/ourservices/ourservices.component';
import { CorporatesolutionComponent } from './pages/corporatesolution/corporatesolution.component';
import { WethechangeComponent } from './pages/wethechange/wethechange.component';
import { StudentcareComponent } from './pages/studentcare/studentcare.component';
import { InstantEducationSystemComponent } from './pages/instant-education-system/instant-education-system.component';
import { SundayScholarComponent } from './pages/sunday-scholar/sunday-scholar.component';
import { BecomeourworldComponent } from './pages/becomeourworld/becomeourworld.component';
import { ClassroomComponent } from './pages/classroom/classroom.component';


const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'homepage',
  //   component: ConstructionComponent
  // },
  {
    path: '',
    component: HomepageComponent
  },
  {
    path: 'about-us',
    component: AboutusComponent
  },
  {
    path: 'contact-us',
    component: ContactusComponent
  },
  {
    path: 'director-message',
    component: DirectorComponent
  },
  {
    path: 'wings2win-courses',
    component: AllcoursesComponent
  },
  {
    path: 'wings2win-services',
    component: OurservicesComponent
  },
  {
    path: 'wings2win-corporate-solution',
    component: CorporatesolutionComponent
  },
  {
    path: 'wings2win-the-change',
    component: WethechangeComponent
  },
  {
    path: 'wings2win-student-care',
    component: StudentcareComponent
  },
  {
    path: 'wings2win-sunday-scholar',
    component: SundayScholarComponent
  },
  {
    path: 'wings2win-instant-education-system',
    component: InstantEducationSystemComponent
  },
  {
    path: 'become-wings2win-world',
    component: BecomeourworldComponent
  },
  {
    path: 'wings2win-classroom',
    component: ClassroomComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routeComponents = [HomepageComponent]