import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  title = 'Wings2Win';

  onActivate() {
    window.scroll(0, 0);
  }
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }
  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'title', content: this.title },
      { name: 'description', content: 'Wings2Win is an academy of impact learning & life skills management. It is a one-stop solution for all students, candidates, professionals and every individual who is passionate to choose excellence in his/her life in various fields.' },
      { name: 'keywords', content: 'Wings2Win, presonality development, life skills management, interview management, spoken english, career growth courses in bhubaneswar' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Wings2Win' },
      { name: 'date', content: '2020-08-01', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }
}