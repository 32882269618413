import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-sunday-scholar',
  templateUrl: './sunday-scholar.component.html',
  styleUrls: ['./sunday-scholar.component.scss']
})
export class SundayScholarComponent implements OnInit {
  title = 'SUNDAY SCHOLAR - An initiative of Wings2Win';
  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'It is a Wings2Win initiative towards all students and professionals to utilize Sunday as not just a holiday rather the best opportunity day to bring self-impactable change with spending little time with Wings2Win for getting success in all spheres of life.' }
    );
  }
}
