import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instant-education-system',
  templateUrl: './instant-education-system.component.html',
  styleUrls: ['./instant-education-system.component.scss']
})
export class InstantEducationSystemComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
