// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDej1tmOfVIUsljy-F2PnSWs1u5apbxeBU",
    authDomain: "wings2win-in.firebaseapp.com",
    databaseURL: "https://wings2win-in.firebaseio.com",
    projectId: "wings2win-in",
    storageBucket: "wings2win-in.appspot.com",
    messagingSenderId: "450569786669",
    appId: "1:450569786669:web:7202b57d4b7618fdc7afa4"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
